<template>
  <v-container id="mixologia">
    <base-card light>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="5"
          sm="12"
        >
          <v-img
            :src="require('@/assets/heads/head2green.png')"
            class="darken-4"
            height="250"
            width="100%"
            contain
          />
        </v-col>
        <v-col
          md="7"
          sm="12"
        >
          <h1 class="display-2 font-weight-light mb-5">
            {{ content.mixology }}
          </h1>

          <div
            class="subheading pl-2 mb-4 text-justify"
            v-html="content.mixologyDesc"
          ></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-img
            :src="require('@/assets/mezcal/cocteleria_5_2.png')"
            class="darken-4"
            width="100%"
            contain
            fill-height
          />
        </v-col>
      </v-row>

    </base-card>
    <mixologia-coctel
      v-for="coctel in cocteles"
      :key="coctel.id"
      :value="coctel"
      class="darken-4"
      height="100px"
      width="100%"
      contain
    />
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Mixologia',
    components: {
      MixologiaCoctel: () => import('@/components/base/MixologiaCoctel'),
    },
    computed: {
      ...mapState(['cocteles']),
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].page
      },
    },
  }
</script>

<style scoped>
</style>
